import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import LinkAnchor from './../atoms/LinkAnchor';
import Container from './../atoms/Container';

const icon_medicine_v2 = '/images/icon-medicine-v2.svg';
const icon_book_appointment = '/images/icon-book-appointment.svg';
const icon_treatments = '/images/icon-treatments.svg';
const icon_services_v2 = '/images/icon-services-v2.svg';
const icon_health_blogs = '/images/icon-health-blogs.svg';
const icon_find_doctor = '/images/icon-find-doctor.svg';
const icon_check_feed_v2 = '/images/icon-check-feed-v2.svg';
const icon_webinar_v2 = '/images/icon-webinar-v2.svg';
const icons_view_quiz_v2 = '/images/icons-view-quiz-v2.svg';
const icon_order = '/images/icon-order.svg';
const icon_learning_v2 = '/images/icon-learning-v2.svg';
const icon_health_feed = '/images/icon-health-feed.svg';
const icon_clinics = '/images/icon-clinics.svg';
const vaidya_tool_dr = '/images/vaidya-tool-dr.svg';
const icon_doctor_quick_link = '/images/icon-doctor-male.svg';
const nirog_partner_v2 = '/images/nirog-partner-v2.svg';
const icon_offers = '/images/icon-offers.svg';

import { getAuth } from '../services/identity.service';
import CustomScroll from './../atoms/CustomScroll';
import ModalVerifyDocumentPopUps from 'molecules/Modals/ModalVerifyDocumentPopUps';
import * as moengage from "services/moengage.service";
import mo_engage_click_data from "../assets/mo_engage/click_data";
import { onClickNirogPartner } from '../components/Reseller/ResellerMoengage';
import { moEngageLmsQuickLink } from 'components/LMS/LmsMoengage';
import { moEngageFeedQuickLink } from 'components/feeds/FeedMoEngage';
import { moEngageBlogQuickLink, moEngageBlogMediaType } from 'components/blog/BlogMonengage';
import TextIcon from 'molecules/TextIcon';
import { CLR } from 'styles/theme';
import { USER_TYPES } from "../constant/index"
import { useUserContext } from '@/components/context/UserContext';
import { hasAccess } from 'core/common/userAccess';

import useWindowSize from 'customHook/useWindowSize'
import QuickLinksWeb from '@/components/common/QuickLinksWeb';
import ModalAppleAndPlayStore from '@/components/Modal/ModalAppleAndPlayStore';
import AppBar from '@/components/HomePage/AppBar';
import { getMedicineCategoryListWithoutAuth } from 'services/medicine.service';
import MakeAppointment from '@/components/BookAnAppointment/MakeAppointment';
import { ServicesDropdown, TreatmentDropdown, HealthConditions } from 'constant/smartcenter';
import { redirectionAfterLogin } from 'core/common/redirectionAfterLogin';
import AppBarWrapper from '@/components/HomePage/AppBarWrapper';


const styles = {
    textIcon: 'gap-2.5 lg:gap-0.5 leading-4 lg:font-normal hover:text-primary1-500',
    a: 'group flex h-full bg-gray-50 lg:bg-transparent-0 rounded-lg lg:rounded-none shadow-sm lg:shadow-none py-1.5 pl-2 pr-1 lg:p-0 w-31 lg:w-auto flex-shrink-0',
}

const TextIconProps = {
    type: 'caption',
    className: styles?.textIcon,
    fontWeight: 600,
    direction: 'LEFT',
    directionLg: 'TOP',
    iconImageHeight: 24,
    iconImageWidth: 24,
}

const HeaderSecondary = ({ className = '', categoryFilters, appointmentBenefitsList }) => {
    const router = useRouter();

    const [isVerifiedUser, setIsVerifiedUser] = useState(false);
    const [usertype, setUsertype] = useState(null);
    const route = router.pathname.split('/')[1];
    const [updateIsVerified, setUpdateIsVerified] = useState()
    const [modalForDelete, setModalForDelete] = useState(false);
    const [isEnableLMS, setIsEnableLMS] = useState(false);
    const [openStoreModal, setOpenStoreModal] = useState({ type: '', isOpen: false });
    const [popularLinkList, setPopularLinkList] = useState()
    const [isOpenAppointmentModal, setIsOpenAppointmentModal] = useState(false);

    const { userAccess } = useUserContext();
    const [width] = useWindowSize();
    const [auth, setAuth] = React.useState(getAuth());
    const entity = mo_engage_click_data.entity;
    const attributes = {};
    attributes[entity["path"]["source_of_click"]] = entity.path.from_home;
    const phpBucketUrl = (process.env.NODE_ENV == 'development') ? "https://stg3web.1veda.in" : process.env.NEXT_PUBLIC_API_URL_PHP;
    const { user } = useUserContext();

    const redirectPopup = () => {
        setModalForDelete(true)
    }

    const handleModalForDeleteStateChange = ({ event, confirm }) => {
        if (confirm) {
            setModalForDelete(false);
            deletefeed(feedToDelete);
        } else {
            setModalForDelete(false);
        }
    };

    const handleVaidyaClick = (type) => {
        if (!isVerifiedUser) {
            router.push('/login-practitioner')
        }
        else if (updateIsVerified == 1) {
            if (type == 'vaidya_tool') {
                window.location.href = process.env.NEXT_PUBLIC_VEDIC_TOOL_LINK
            }
        } else {
            redirectPopup()
        }
    }

    const activeLink = (currentRoute) => route === currentRoute ? CLR.primary500.text : CLR.gray900.text;

    useEffect(()=>{(async () => {
        let user_status = await getAuth()
        setIsVerifiedUser(user_status);
        setUpdateIsVerified(user_status?.user?.tbl_doctor_profile?.is_verified)
        setUsertype(user_status?.user?.userType);

    })()}, [router.pathname]);


    const handlerOpenStoreModal = ({ type }) => setOpenStoreModal({ isOpen: true, type });

    const transformCategory = async (category) => {
        const fetchCategory = category?.data?.entity?.categories.find(categoryFilter => categoryFilter.id === 1);
        const classicalCategoryList = fetchCategory?.children.find(classicalCategory => classicalCategory.id === 2);
        const patentedCategoryList = fetchCategory?.children.find(patentedCategory => patentedCategory.id === 3);

        const categoryFilters = [
            ...(!auth ? [
                {
                    label: 'Buy Medicines',
                    dataAutomation: 'header-buy-medicines',
                    redirection: '/medicines',
                    onClick: event => {
                        redirectionAfterLogin({ event, auth, router, redirectUrlLoggedIn: '/medicines', redirectUrlLoggedOut: '/login-practitioner' })
                    }
                }
            ] : []),
            {
                label: "Brand",
                subMenuList: category?.data?.entity?.brand || [],
                multipleSubMenu: true,
                dataAutomation: 'header-brand',
                moEngageEventName: 'click_brand_dropdown'
            },
            {
                label: "Classical Medicine",
                subMenuList: classicalCategoryList?.children || [],
                multipleSubMenu: true,
                dataAutomation: 'header-classical-medicine',
                moEngageEventName: 'click_classical_dropdown'
            },
            {
                label: "Patented Medicine",
                subMenuList: patentedCategoryList?.children || [],
                multipleSubMenu: true,
                dataAutomation: 'header-patented-medicine',
                moEngageEventName: 'click_patented_dropdown',
            },
            {
                label: 'All Medicines',
                dataAutomation: 'header-all-medicines',
                redirection: '/medicines/allMedicines'
            },
        ]

        setPopularLinkList(categoryFilters);
    }

    useEffect(() => {
        const fetchData = async () => {
            if (!categoryFilters && auth?.user?.userType !== 1) {
                const categoryListResponse = await getMedicineCategoryListWithoutAuth();
                await transformCategory(categoryListResponse);
            } else {
                setPopularLinkList(categoryFilters);
            }
        };
    
        fetchData();
    }, [categoryFilters]);
    
    const openHandleBookAppointment = (e) => {
        e.preventDefault();
        // setIsOpenAppointmentModal(true);
        router.push('/');
    }

    const IndividualDropdown = [
        { menuHeader: 'Buy Medicines' },
        ...HealthConditions,
        { menuDivider: true },
        { label: 'Request Appointment', id: 'request-appointment', redirection: '/request-appointment' },
        { menuDivider: true },
        { menuHeader: 'Treatments', },
        ...TreatmentDropdown,
        { menuDivider: true },
        { menuHeader: 'Services', },
        ...ServicesDropdown,
    ]

    const loginPage = ["/login", "/sign-up", "/login-individual", "/login-practitioner", "/sign-up-individual", '/sign-up-practitioner'];
    const hideAppBarMSite = ['medicines/allMedicines'];
    return (
        <>
            {!loginPage.includes(router.asPath) && <div>{!isVerifiedUser &&
                <AppBarWrapper handlerOpenStoreModal={handlerOpenStoreModal} width={{ width }} />
            }
            </div>}
            {!loginPage.includes(router.asPath) ? <div className={`${className} ${isVerifiedUser ? '' : 'hidden lg:flex'} lg:flex lg:whitespace-normal xl:whitespace-nowrap flex bg-transparent-0 lg:bg-basic-white justify-center relative z-30 items-center border-b border-gray-200  lg:h-13.5 xl:px-5 `}>
                {width > 1023
                    ? <QuickLinksWeb {...{ auth, isVerifiedUser, activeLink, entity, usertype, updateIsVerified, redirectPopup, userAccess, isEnableLMS, attributes, handlerOpenStoreModal, popularLinkList, handleVaidyaClick, openHandleBookAppointment, IndividualDropdown }} />
                    : router?.pathname == '/' ? null : <CustomScroll className={`${auth?.user?.userType === USER_TYPES.DOCTORS? 'pb-3':null}`} >
                        <Container type='static' className={`px-2 flex items-center w-full`}>

                            <div className={`items-end flex-row  ${auth?.user?.userType === USER_TYPES.DOCTORS?'h-10 mt-1':''}  w-full  lg:h-auto flex gap-x-1.5 lg:gap-5 hover:text-lg:px-7.5`}>
                                {(auth?.user?.userType === USER_TYPES.DOCTORS && (route != 'practitioner' && route != 'login-practitioner' && route != 'sign-up-practitioner')) &&
                                    <>
                                        <LinkAnchor href={isVerifiedUser ? '/medicines' : '/sign-up-practitioner'} className={`${isVerifiedUser ? '' : 'disableLink'} ${route === 'medicines' ? 'activeHeaderLink' : ''} ${styles.a}`} dataAutomation='buy-medicines'>
                                            <TextIcon onClick={() => moengage.trackEvent(entity.buy_medicines_click.event_name, attributes)} color={route === 'medicines' ? CLR.primary500.text : CLR.gray900.text}  {...(width > 1023 ? {} : { iconImageSrc: icon_medicine_v2 })} {...TextIconProps}>
                                                Buy Medicines
                                            </TextIcon>
                                        </LinkAnchor>

                                        {usertype === null || ![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(usertype) && <LinkAnchor onClick={(e) => {
                                            e.preventDefault();
                                            onClickNirogPartner(() => {
                                                if (!isVerifiedUser) {
                                                    // router.replace('/sign-ip')
                                                    router.push('/reseller');
                                                } else if (updateIsVerified === 1 && (usertype === USER_TYPES?.DOCTORS || usertype === USER_TYPES?.DOCTOR_DISTRIBUTOR || usertype === USER_TYPES?.STUDENTS)) {
                                                    router.push('/reseller');
                                                } else {
                                                    redirectPopup();
                                                }
                                            });
                                        }} className={`${isVerifiedUser ? '' : 'disableLink'} ${route === 'reseller' ? 'activeHeaderLink' : ''} ${styles.a}`} dataAutomation='nirog-partner'>
                                            <TextIcon {...(width > 1023 ? {} : { iconImageSrc: nirog_partner_v2 })} color={activeLink('reseller')} {...TextIconProps} >
                                                Nirog Partner
                                            </TextIcon>
                                        </LinkAnchor>}

                                        {
                                            usertype === null || ![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(usertype) ? (
                                                <LinkAnchor onClick={(e) => { e.preventDefault(); handleVaidyaClick('vaidya_tool') }} className={` ${styles.a}`} dataAutomation='VaidyaTool_QuickLink'>
                                                    <TextIcon onClick={() => moengage.trackEvent(entity.vaidya_tool_click.event_name, attributes)} {...(width > 1023 ? {} : { iconImageSrc: vaidya_tool_dr })} {...TextIconProps} >
                                                        Vaidya Tool
                                                    </TextIcon>
                                                </LinkAnchor>
                                            ) : (null)
                                        }

                                        <LinkAnchor href={`/offers`} className={`${styles.a}`} dataAutomation='find-doctors'>
                                            <TextIcon onClick={() => moengage.trackEvent(entity.doctor_click.event_name, attributes)} color={activeLink('offers')} {...(width > 1023 ? {} : { iconImageSrc: icon_offers })} {...TextIconProps} >
                                                Offers
                                            </TextIcon>
                                        </LinkAnchor>

                                        {
                                            (userAccess === null || hasAccess(userAccess?.Feed?.feedAccess)) && (usertype === null || ![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(usertype)) ? (
                                                <LinkAnchor href={'/feeds'} className={`${isVerifiedUser ? '' : 'disableLink'} ${route === 'feeds' ? 'activeHeaderLink' : ''} ${styles.a}`} dataAutomation='feed'>
                                                    <TextIcon onClick={() => moEngageFeedQuickLink()} color={activeLink('feeds')} {...(width > 1023 ? {} : { iconImageSrc: icon_check_feed_v2 })} {...TextIconProps} >
                                                        Feed
                                                    </TextIcon>
                                                </LinkAnchor>
                                            ) : (null)
                                        }

                                        {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(usertype) && <LinkAnchor href={'/learning'} className={`${isVerifiedUser ? '' : 'disableLink'} ${route === 'learning' ? 'activeHeaderLink' : ''} ${styles.a}`} dataAutomation='LMS_quick_link'>
                                            <TextIcon onClick={() => moEngageLmsQuickLink()} color={activeLink('learning')} {...(width > 1023 ? {} : { iconImageSrc: icon_learning_v2 })} {...TextIconProps} >
                                                Learning
                                            </TextIcon>
                                        </LinkAnchor>}

                                        {
                                            (userAccess === null || hasAccess(userAccess?.Webinar?.webinarAccess)) && (usertype === null || ![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(usertype)) ? (
                                                <LinkAnchor href={'/webinar'} className={`${isVerifiedUser ? '' : 'disableLink'} ${route === 'webinar' ? 'activeHeaderLink' : ''} ${styles.a}`} dataAutomation='webinars'>
                                                    <TextIcon onClick={() => moengage.trackEvent(entity.webinar_click_webinar_quick_link.event_name, attributes)} color={activeLink('webinar')} {...(width > 1023 ? {} : { iconImageSrc: icon_webinar_v2 })} {...TextIconProps}>
                                                        Webinar
                                                    </TextIcon>
                                                </LinkAnchor>
                                            ) : (null)
                                        }

                                        {
                                            (userAccess === null || hasAccess(userAccess?.Quiz?.quizAccess)) && (usertype === null || ![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(usertype)) ? (
                                                <LinkAnchor href={'/quizzes'} className={`${isVerifiedUser ? '' : 'disableLink'} ${route === 'quizzes' ? 'activeHeaderLink' : ''} ${styles.a}`} dataAutomation='quiz'>
                                                    <TextIcon onClick={() => moengage.trackEvent(entity.quiz_click.event_name, attributes)} color={activeLink('quizzes')}  {...(width > 1023 ? {} : { iconImageSrc: icons_view_quiz_v2 })} {...TextIconProps}>
                                                        Quiz
                                                    </TextIcon>
                                                </LinkAnchor>
                                            ) : (null)
                                        }


                                        {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(usertype) && <LinkAnchor href={`/blogs`} className={`${styles.a}`} dataAutomation='blog'>
                                            <TextIcon onClick={() => { moEngageBlogQuickLink(); moEngageBlogMediaType("/blogs") }} color={activeLink('blogs')} {...(width > 1023 ? {} : { iconImageSrc: icon_health_feed })} {...TextIconProps} >
                                                Health Blogs
                                            </TextIcon>
                                        </LinkAnchor>}

                                        {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(usertype) && <LinkAnchor href={`/ayurveda/clinics`} className={`${styles.a}`} dataAutomation='clinics'>
                                            <TextIcon onClick={() => moengage.trackEvent(entity.clinic_click.event_name, attributes)} color={activeLink('clinics')} {...(width > 1023 ? {} : { iconImageSrc: icon_clinics })} {...TextIconProps} >
                                                Find Clinics
                                            </TextIcon>
                                        </LinkAnchor>}

                                        {![USER_TYPES?.CHEMIST, USER_TYPES?.CHEMIST_DISTRIBUTOR, USER_TYPES?.RETAILER].includes(usertype) && <LinkAnchor href={`/ayurveda/doctors`} className={`${styles.a}`} dataAutomation='find-doctors'>
                                            <TextIcon onClick={() => moengage.trackEvent(entity.doctor_click.event_name, attributes)} color={activeLink('/ayurveda/doctors')} {...(width > 1023 ? {} : { iconImageSrc: icon_doctor_quick_link })} {...TextIconProps} >
                                                Find Doctors
                                            </TextIcon>
                                        </LinkAnchor>}






                                    </>}

                            </div>
                        </Container>
                    </CustomScroll>}
                <ModalVerifyDocumentPopUps
                    showModalFor={updateIsVerified == 4 ? "verifyDocumentsRejected" : updateIsVerified == 2 ? "verifyDocumentsUnderReview" : updateIsVerified == 0 ? "verifyDocumentsPending" : null}
                    isModalOpen={modalForDelete}
                    handlerModalClose={() => setModalForDelete(false)}
                    handleModalStateChange={handleModalForDeleteStateChange}
                    reason={user?.reason}
                />

                {openStoreModal && <ModalAppleAndPlayStore
                    isModalOpen={openStoreModal?.isOpen} handleModalStateChange={() => setOpenStoreModal({ isOpen: false })}
                    dataAutomation='apple-and-palystore'
                    type={openStoreModal.type}
                    usertype={auth?.user?.userType}
                />}

                {isOpenAppointmentModal && <MakeAppointment
                    isModalOpen={isOpenAppointmentModal}
                    setIsModalOpen={setIsOpenAppointmentModal}
                    section={'individual'}
                    buttonId={'makeAppointment'}
                    isCoverContent={false}
                    appointmentBenefitsList={appointmentBenefitsList}
                    isPopup={true}
                />}
            </div> : null}
        </>
    )
}

export default HeaderSecondary