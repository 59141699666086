import { deafultImagePath } from "constant/medicine/default-medicine-parameters";
import { getTransformedPackagesPrices } from "../medicine-detail/get-transformed-packages-prices";

export const getTransformedProducts = (productsList, userCategory) => {
  const transformedProductsList = [];
  const bucketUrl = process.env.NEXT_PUBLIC_BUCKET_URL_CATEGORY.replace('images/package/', '');
  productsList?.map((info) => {
    let similarProductObj = {
      id: info.id,
      slug: info.slug,
      brand_name: info.brand?.name,
      preview_img: info.ProductImages && info.ProductImages.length > 0 ? `${bucketUrl}${info.ProductImages?.find((img) => img.isDefault)?.Image.imagePath}`
        : deafultImagePath,
      name: info.name,
      is_deal: info.is_deal,
      bookmark_medicine: info.hasOwnProperty('MedicineBookmark') && info.MedicineBookmark !== null,
      price_data: getTransformedPackagesPrices(info.item_code, info.discount, userCategory),
      applicableCouponCode: info.applicableCouponCode ? info.applicableCouponCode : null,
      campaignName: info.campaignName,
      campaignStartDate: info.campaignStartDate,
      campaignEndDate: info.campaignEndDate,
      inStock: info.inStock
    };
    transformedProductsList.push(similarProductObj);
  });
  return transformedProductsList;
};
