import { useEffect, useState } from 'react';
import Modal from 'atoms/Modal'
import BodyForModal from 'molecules/Modals/BodyForModal'
import Heading from 'atoms/Heading'
import IconClose from 'atoms/SvgIcons/IconClose.svg'
import Input from 'atoms/Input'
import Button from 'atoms/Button'
import ModalOnSellOTP from './ModalOnSellOTP';
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup';
import { yupValidator } from 'lib/yup-validator';
import Textarea from 'atoms/forms/Textarea';
import ThankYouSellCard from './ThankYouSellCard';
import { sellBusinessQuery, sellBusinessOtp } from "./../../services/user.service";


const ModalSubmitQuery = ({ className, isModalOpen, handlerModalClose, heading, requestType=1 }) => {
    const [openSellOTPModal, setOpenSellOTPModal] = useState(false)   
    const [openSellThankModal, setOpenSellThankModal] = useState(false)
    const [queryData, setQueryData] = useState({})
    const [alertMsg, setAlertMsg] = useState(null)

    const {
        register,
        handleSubmit,
        control,
        watch,
        setError,
        setValue,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
        resolver: yupValidator(
            yup.object().shape({
                fullName: yup.string().required("Full Name is Required"),
                mobile: yup.string().required("Mobile number is required").matches(/^((?:(?:\+|0{0,2})93(\s*[\-]\s*)?|[0]?)?[6789]\d{9})*$/, "Mobile number is not valid"),
                email: yup.string().email().required("Email is Required"),
                companyName: yup.string()
                .when('requestType', {
                    is: val => val && val==2,
                    then: yup.string().required("Company name is Required")
                }),
                jobTitle: yup.string()
                .when('requestType', {
                    is: val => val && val==2,
                    then: yup.string().required("Job title is Required")
                }),
                description: yup.string().required("Message is Required"),
            }),
        )
    });

    const onSubmit = async (data) => {
        let apiData = {...data, type: requestType};
        const queryResponse = await sellBusinessOtp(apiData);
        setQueryData(apiData);
        if(queryResponse.status){
            handlerOtpModalOpen();
        }
    }

    const handlerOtpModalOpen = () => {
        handlerModalClose(false);
        setOpenSellOTPModal(true);
    };

    const handlerOtpModalClose = () => {
        setOpenSellOTPModal(false);

    };

    const handleResendOtp = async() => {
        const queryResponse = await sellBusinessOtp(queryData);
        if(queryResponse.status){
            setAlertMsg({ message: "Resent OTP successfully!", type: "success" });
           handlerOtpModalOpen();
        } else {
            setAlertMsg({ message: queryResponse?.message, type: "error" });
        }
    }
 
    const handlerThanksModalOpen = async(otpValue) => {
        let apiData = {...queryData, otp: otpValue};
        const queryResponse = await sellBusinessQuery(apiData);
        if(queryResponse?.data?.status){
            setOpenSellThankModal(true);
            setOpenSellOTPModal(false);
        }
        if (queryResponse?.data?.message === "Successfully sent Otp!") {
            setAlertMsg({ message: "Resent OTP successfully!", type: "success" });
        } else {
            setAlertMsg({ message: queryResponse?.data?.message, type: "error" });
        }
    };

    const handlerThanksModalClose = () => {
        setOpenSellThankModal(false);
    };
    
    useEffect(()=>{
        setValue('requestType', requestType)
    }, [requestType])

    return (
        <>
            <Modal
                type="withoutCloseIcon2"
                className={`${className} relative p-0 max-w-[440px] min-h-auto`}
                isModalOpen={isModalOpen}
                isShowCloseBtn={false}
                handlerModalClose={handlerModalClose}
                dataAutomation='sell-on'
            >
                <div className="bg-primary1-900 pl-5 sm:pl-8 pr-4 py-4 flex justify-between">
                    <Heading type="h3" className='!text-basic-white !font-semibold text-1.5xl font-Montserrat'>{heading}</Heading>
                    <IconClose width={24} className="stroke-gray-50" onClick={handlerModalClose} />
                </div>
                <BodyForModal className="pt-5 pb-6 px-5 sm:px-8 min-h-[400px] h-min max-h-[calc(100vh_-_60px)] md:max-h-[calc(100vh_-_150px)] relative customScrollbar flex-grow object-contain items-center justify-center">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            type="text"
                            placeholder='Full Name'
                            id="fullName"
                            register={register}
                            error={errors.fullName}
                            isRequiredField={true}
                        />
                        <Input
                            type="number"
                            placeholder='Mobile Number'
                            id="mobile"
                            register={register}
                            error={errors.mobile}
                            isRequiredField={true}
                            patternRequired={true}
                            reger="^[789]\d{9}$"
                            minLength={10}
                            maxLength={10}
                        />
                        <Input
                            type="text"
                            placeholder='Email ID'
                            id="email"
                            register={register}
                            error={errors.email}
                        />
                        <Input
                            type="hidden"
                            id="requestType"
                            register={register}
                        />
                       {requestType === 2 ? <><Input
                            type="text"
                            placeholder='Company Name'
                            id="companyName"
                            register={register}
                            error={errors.companyName}
                        />
                        <Input
                            type="text"
                            placeholder='Job Title'
                            id="jobTitle"
                            register={register}
                            error={errors.jobTitle}
                        /> </>: null}
                        <Textarea
                            className="inputDefault min-h-[80px]"
                            id="description"
                            placeholder={`${requestType === 2 ? 'Please enter your comments...' : 'Please enter details of medicine needed...'}`}
                            register={register}
                            type="text"
                            textareaRows={10}
                            maxLength={4000}
                            error={errors.description}
                        />
                        <div className="flex justify-center w-full mt-6">
                            <Button type='submit' variant='Primary' className='rounded-lg w-full'>                            
                                Submit and Get OTP
                            </Button>
                        </div>
                    </form>
                </BodyForModal>
            </Modal>
            <ModalOnSellOTP alertMsg={alertMsg} setAlertMsg={setAlertMsg} mobileNo={queryData?.mobile} isModalOpen={openSellOTPModal} handlerOtpModalClose={handlerOtpModalClose} handlerThanksModalOpen={handlerThanksModalOpen} handleResendOtp={handleResendOtp}/>
            <ThankYouSellCard isModalOpen={openSellThankModal} handlerThanksModalClose={handlerThanksModalClose}/>
        </>
    )
}

export default ModalSubmitQuery