import { useState } from 'react';
import ModalSubmitQuery from '../ModalSellOn/ModalSubmitQuery';

const NeedBulkMedicine = () => {
    const [openSellModal, setOpenSellModal] = useState(false)
    
    const handlerSellModalOpen = () => {
        setOpenSellModal(true);      
      };

    const handlerModalClose = () => {
        setOpenSellModal(false);
      };

    return (
        <div className='flex w-full h-full items-stretch self-center'>            
            <div onClick={handlerSellModalOpen} className='font-semibold text-sm cursor-pointer text-gray-800'>Need Medicines in Bulk?</div>
            <ModalSubmitQuery heading='Submit your query' isModalOpen={openSellModal} setOpenSellModal={setOpenSellModal} handlerModalClose={handlerModalClose} requestType={1}/>           
        </div>
    )
}

export default NeedBulkMedicine