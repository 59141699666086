import React, { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import InfiniteScroll from "react-infinite-scroll-component";
import { MenuItem } from "@szhsin/react-menu";

import Input from "atoms/Input";
import ReactSelect from "atoms/forms/ReactSelect";
import EcommSearchMenu from "./EcommSearchMenu";
import SkeletonLoader from 'atoms/skeleton/SkeletonLoader';
import UserCard from "molecules/UserCard";
import SearchTermCard from "molecules/SearchTermCard";

import IconSearch from "atoms/SvgIcons/IconSearch.svg";
import IconLocationGrey from "atoms/SvgIcons/IconLocationGrey.svg";
import IconClose from 'atoms/SvgIcons/IconClose.svg.js';

import { getLeadCities } from "services/my-leads";
import { getAllDoctors } from 'services/ayurvedic-doctor.service'

import { formatLocationText } from 'utils';
import { useUserContext } from "@/components/context/UserContext";
import { getAllClinicsV1 } from "services/clinic.service";
import { getSearchResults } from "services/search.service";
import { GetServicesSpecilizationList } from "services/user.service";

const bucketUrl = process.env.NEXT_PUBLIC_BUCKET_URL;


const IndividualsSearchSection = React.forwardRef(({ className, search = "", searchLocation = "India", menuWidth = 0, type = "doctor", ...props }, ref) => {
    const limit = 25;

    const [searchText, setSearchText] = useState(search);
    const [location, setLocation] = useState(searchLocation);
    const [searchResults, setSearchResults] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [offset, setOffset] = useState(0);
    const [cityList, setCityList] = useState([]);
    const [isShowSearch, setIsOpenSearch] = useState(false);
    const [isFetchSearch, setIsFetchSearch] = useState(false);
    const [showSearchOptions, setShowSearchOptions] = useState(false);
    const [commonSpecialities, setCommonSpecialities] = useState([]);

    const router = useRouter();

    const anchorRef = useRef(null);

    const { isShowSearchResult, setIsShowSearchResult } = useUserContext();
    const locationSlug = loc => {
        const locationPart = loc?.split(',')[0];
        return locationPart.replace(/\s+/g, ' ').trim().replace(/\s+/g, '-').toLowerCase();
    };

    const searchData = async ({ search, city, isInitialFetch = false }) => {
        await getSearchResults({
            page: 1,
            limit,
            search,
            city: city != "India" ? city?.toLowerCase()?.replaceAll(", ", "-") : ""
        })
            .then((response) => {
                setSearchResults([
                    ...(
                        response?.entity?.doctors?.rows?.length > 10
                                ?
                            response?.entity?.doctors?.rows?.slice(0, 10)
                                :
                            response?.entity?.doctors?.rows
                    ) ?? []
                ]);
                setKeywords( [
                    ...(
                            response?.entity?.keywords?.rows?.slice(0,10)
                    ) ?? []
                ]);
                setOffset(isInitialFetch ? limit : offset + limit);
                setIsFetchSearch(false);
            })
            .catch(error => {
                throw error;
            });
    };

    const getDoctor = async ({ search, city, isInitialFetch = false }) => {
        const response = await getAllDoctors({ page: 1, limit, search: search, city: city != "India" ? city : "" })
            .then((response) => {
                setSearchResults([...(response?.entity?.rows || [])]);
                setOffset(isInitialFetch ? limit : offset + limit);
                setIsFetchSearch(false);
            })
            .catch(error => {
                throw error;
            });
    };

    const getClinic = async ({ search, city, isInitialFetch = false }) => {
        const response = await getAllClinicsV1({ skip: 0, limit: limit, clinicSearch: search, locationSearch: city != "India" ? city : "", state: '', services: '', fee: '', feeResult: '', clinicIds: "", clinicSlugs: "", isSmartClinic: "" })

            .then((response) => {
                setSearchResults([...(response?.entity?.rows || [])]);
                setOffset(isInitialFetch ? limit : offset + limit);
                setIsFetchSearch(false);
            })
            .catch(error => {
                throw error;
            });
    };

    const onSearchEnter = (e) => {
        if (type == "clinic") {
            if (searchText?.length < 3) return;
            if (e.key === 'Enter' && searchText) {
                setIsShowSearchResult(false);
                if (location === "India") {
                    router.push(`/ayurveda/clinics/${searchText !== "" ? `?search=${searchText}` : ""}`);
                } else if (location && location !== "India") {
                    router.push(`/ayurveda/${locationSlug(location) ?? router?.query?.location}/clinics${searchText !== "" ? `?search=${searchText}` : ""}`);
                } else {
                    router.push(`/ayurveda/clinics/${searchText !== "" ? `?search=${searchText}` : ""}`);
                }
            }
        }
    }

    const handleClickOnSearchBox = () => {
        if (searchText?.length > 0) {
            setIsOpenSearch(true);
        } else {
            setShowSearchOptions(true);
        }
    }

    useEffect(() => {
        // Fetching cities list for the dropdown
        getLeadCities()
            .then(response => {
                if (response.status) {
                    setCityList([
                        { label: "India", value: "India" },
                        ...response?.entity.map(item => {
                            const city = formatLocationText(`${item?.city}${item?.state ? `, ${item?.state}` : ""}`);
                            return {
                                label: city,
                                value: city,
                            }
                        })
                    ]);
                };
            })
            .catch(error => {
                console.log(error);
                throw error;
            });

        // Fetching Common Specialities
        const getCommonSpecialities = async () => {
            const response = await GetServicesSpecilizationList(1);
            if (response?.status) {
                setCommonSpecialities(response?.entity?.rows);
            }
        };
        getCommonSpecialities();
    }, []);

    useEffect(() => {
        let trimmedText = searchText.replace(/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g, "").trim()
        if (trimmedText?.length < 3) {
            setSearchResults([]);
            setKeywords([]);
            return;
        }
        let city = location ? location : ''
        let delayDebounceFn = setTimeout(async () => {
            setIsFetchSearch(true);
            type == 'doctor' ?
                searchData({ search: trimmedText, city: city, isInitialFetch: true }) :
                getClinic({ search: trimmedText, city: city, isInitialFetch: true });


        }, type == "doctor" ? 300 : 800);
        return () => clearTimeout(delayDebounceFn);
    }, [searchText, location]);

    useEffect(() => {
        const onClick = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setIsOpenSearch(false);
                setShowSearchOptions(false);
            }
        };
        document.body.addEventListener("click", onClick);
        return () => {
            document.body.removeEventListener("click", onClick);
        };
    }, [ref]);

    useEffect(() => {
        setSearchText(router?.query?.search ?? "")
    }, [router?.query?.search])


    return (
        <div className={`${className} z-1`}>
            <div className='flex gap-3 h-10'>
                <ReactSelect
                    className="h-full w-5/12 xxs:w-[104px] sm:w-[115px] lg:w-[198px]"
                    customZIndex={50}
                    onChange={(data) => {
                        setLocation(data?.value);
                    }}
                    value={{ label: location ?? "India", value: location ?? "India" }}
                    options={cityList}
                    dropdownHeight={40}
                    selectedValueFontSize={14}
                    optionsFontSize={12}
                    optionPadding="10px 12px"
                    menuWidth={menuWidth}
                    error={""}
                />
                <div className="flex pr-2 h-full w-11/12 border border-gray-300 rounded-lg xxs:w-[266px] sm:w-[325px] lg:w-[450px]" ref={anchorRef}>
                    <Input
                        className="w-full h-full !border-0"
                        placeholderClass="-mt-0.75 text-xs"
                        type="text"
                        ref={ref}
                        placeholder={type == "clinic" ? "Search by Clinic Name" : "Search by Doctor, Disease, Symptoms"}
                        iconIndicator={<IconSearch className="flex-shrink-0 w-5 stroke-gray-400 ml-2 -mr-2" />}
                        id="doctorSearch"
                        isRequiredField={true}
                        containerClass="w-full h-full"
                        value={searchText}
                        onChange={(value) => {
                            if (isShowSearchResult == false) {
                                setIsShowSearchResult(true);
                            }
                            setIsOpenSearch(value?.length > 0);
                            setShowSearchOptions(!(value?.length > 0))
                            setSearchText(value)
                        }}
                        onFocus={type == "doctor" ? handleClickOnSearchBox : () => {}}
                        onKeyPress={onSearchEnter}
                        {...(type == "doctor" ? { autoComplete: "off" } : {})}
                    />
                    {(showSearchOptions || isShowSearch) && type == 'doctor' && 
                        <IconClose
                            className='w-6 fill-none stroke-gray-800 cursor-pointer'
                            onClick={() => {
                                ref.current.value = "";
                                setSearchText("");
                            }}
                        />
                    }
                </div>
            </div>

            {(searchText && isShowSearchResult) ?
                type == "doctor" ?
                    <>
                        <EcommSearchMenu
                            className='rounded-b-2xl rounded-2xl'
                            menuClassName="sm:!w-114"
                            subClass={`${(searchResults?.length == 0 && keywords?.length == 0) ? 'h-fit' : 'min-h-[200px] max-h-[500px]'}`}
                            isShowSearch={isShowSearch}
                            setIsOpenSearch={setIsOpenSearch}
                            title={`${type=='clinic'? `Search Clinics` : ``}`}
                            anchorRef={anchorRef}
                            titleClassName={type == "doctor" ? "!min-h-7 !md:min-h-7 py-1.5 pl-4 bg-gray-100 text-gray-900 text-xs !font-normal !rounded-none" : ""}
                            showTitleCloseIcon={false}
                        >

                            {isFetchSearch ?
                                <SkeletonLoader type="userShortSummery" length={3} isCircularImage={true} minHeight={71} />
                                    :
                                (searchResults?.length || keywords?.length) ? 
                                    <>
                                        {keywords?.length ?
                                            keywords?.map((keyword) => (
                                                <MenuItem className='menu__searchList' key={keyword?.entityName}>
                                                    <SearchTermCard
                                                        className="h-12"
                                                        href={`/ayurveda${location?.length && location !== "India" ? `/${location?.split(",")?.[0]?.toLowerCase()?.replaceAll(" ", "-")}/` : ""}/doctors?search=${encodeURIComponent(keyword?.entityName)}${location?.length && location !== "India" ? `&location=${location}` : ""}`}
                                                        name={keyword?.entityName}
                                                        type={keyword?.entityType}
                                                    />
                                                </MenuItem>
                                            ))
                                        : null}
                                        {searchResults?.length ? <>
                                            <div className={`pl-4 min-h-7 py-1.5 flex justify-between items-center bg-gray-100 border-b border-gray-200 relative z-10 rounded-none font-normal capitalize text-xs text-gray-900`}>
                                                Doctors
                                            </div>
                                                {
                                                    searchResults?.map((item) => (
                                                        <MenuItem className='menu__searchList' key={item?.id}>
                                                            <UserCard
                                                                className="max-h-[70px] !border-gray-200"
                                                                href={item?.href}
                                                                avatar={`${bucketUrl}${item?.profilePic ? item.profilePic : '/images/icon_doctor.svg'}`}
                                                                name={item?.fullNameSalutation}
                                                            />
                                                        </MenuItem>
                                                    ))
                                                }
                                        </> : null}
                                        <MenuItem className='menu__searchList'>
                                            <SearchTermCard
                                                className="h-12"
                                                href={`/ayurveda${location?.length && location !== "India" ? `/${location?.split(",")?.[0]?.toLowerCase()?.replaceAll(" ", "-")}/` : ""}/doctors?search=${encodeURIComponent(searchText)}${location?.length && location !== "India" ? `&location=${location}` : ""}`}
                                                name={`Search for "${searchText}"`}
                                            />
                                        </MenuItem>
                                    </>
                                : 
                                <MenuItem className='menu__searchList'>
                                    <SearchTermCard
                                        className="h-12"
                                        href={`/ayurveda${location?.length && location !== "India" ? `/${location?.split(",")?.[0]?.toLowerCase()?.replaceAll(" ", "-")}/` : ""}/doctors?search=${encodeURIComponent(searchText)}${location?.length && location !== "India" ? `&location=${location}` : ""}`}
                                        name={`Search for "${searchText}"`}
                                    />
                                </MenuItem>
                            }

                        </EcommSearchMenu>
                    </>
                        :
                    <EcommSearchMenu
                        className='rounded-b-2xl'
                        menuClassName="sm:!w-114"
                        subClass='min-h-[200px] max-h-[374px]'
                        isShowSearch={isShowSearch}
                        setIsOpenSearch={setIsOpenSearch}
                        title={`${type=='clinic'? `Search Clinics`:`Doctors`}`}
                        anchorRef={anchorRef}
                        titleClassName={type == "doctor" ? "!min-h-7 !md:min-h-7 py-1.5 pl-4 bg-gray-100 text-gray-900 text-xs !font-normal !rounded-none" : ""}
                        showTitleCloseIcon={false}
                    >

                        {isFetchSearch ?
                            <SkeletonLoader type="userShortSummery" length={3} isCircularImage={true} minHeight={71} />
                                :
                            searchResults?.length ?
                                <>
                                    <InfiniteScroll dataLength={searchResults?.length} next={() => getDoctor({ search: searchText, city: location ? location?.replace(", ", "-") : "" })} hasMore={true} scrollableTarget="scrollableDiv">
                                        {
                                            type == "doctor" ?
                                                searchResults?.map((item) => (
                                                    <MenuItem className='menu__searchList' key={item?.id}>
                                                        <UserCard
                                                            className="!border-gray-200"
                                                            href={item?.href}
                                                            avatar={`${bucketUrl}${item?.profilePic ? item.profilePic : '/images/icon_doctor.svg'}`}
                                                            name={item?.fullNameSalutation}
                                                        />
                                                    </MenuItem>
                                                ))
                                                :
                                                searchResults?.map((item) => (
                                                    <MenuItem className='menu__searchResults' key={item?.id}>
                                                        <UserCard
                                                            className=""
                                                            href={item?.href}
                                                            avatar={`${bucketUrl}${item?.preview_image ? item.preview_image : '/images/icon-clinics.svg'}`}
                                                            name={item?.clinic_name}
                                                        />
                                                    </MenuItem>
                                                ))
                                        }

                                    </InfiniteScroll>
                                </>
                            : <div className='text-2xl text-gray-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '>No result found</div>
                        }
                    </EcommSearchMenu>
                : null
            }
            {searchText?.length == 0 && type == "doctor" &&
                <EcommSearchMenu
                    className='rounded-2xl'
                    menuClassName="sm:!w-114"
                    subClass='min-h-[120px] max-h-[610px]'
                    isShowSearch={showSearchOptions}
                    setIsOpenSearch={setShowSearchOptions}
                    anchorRef={anchorRef}
                    title={"Common Specialities"}
                    titleClassName="!min-h-7 !md:min-h-7 py-1.5 pl-4 bg-gray-100 text-gray-900 text-xs !font-normal !rounded-b-none"
                    showTitleCloseIcon={false}
                >
                    {
                        commonSpecialities?.map((speciality) => (
                            <MenuItem className='menu__searchList' key={speciality?.name}>
                                <SearchTermCard
                                    className="h-12"
                                    href={`/ayurveda${location?.length && location !== "India" ? `/${location?.split(",")?.[0]?.toLowerCase()?.replaceAll(" ", "-")}/` : ""}/doctors?search=${encodeURIComponent(speciality?.name)}${location?.length && location !== "India" ? `&location=${location}` : ""}`}
                                    name={speciality?.name}
                                    type="SPECIALITY"
                                />
                            </MenuItem>
                        ))
                    }
                </EcommSearchMenu>
            }
        </div>
    );
});

export default IndividualsSearchSection;