import React from 'react'

import ImageNext from 'atoms/ImageNext'
import LinkAnchor from 'atoms/LinkAnchor'
import { useRouter } from 'next/router'
import { getAuth } from 'services/identity.service'

const AppBar = ({ className = '', handlerOpenStoreModal, width }) => {
    const router = useRouter();
    const route = router.pathname.split('/')[1];
    const isPractitionerPage = route === 'practitioner';
    const auth = getAuth();
    return (
        <div className={`${className} flex items-center gap-6.5`}>
            {auth ? null :
                <div className='flex items-center gap-1 text-12-16 lg:text-14-18'>
                    <span className='whitespace-normal'>{isPractitionerPage ? 'Not a Doctor/Chemist' : 'Are you a Doctor/Chemist'}? </span>
                    <LinkAnchor href={isPractitionerPage ? '/' : '/practitioner'} className='text-primary1-500 font-bold text-right'>Click Here</LinkAnchor>
                </div>
            }

            <div className='flex gap-6 lg:gap-6.5'>
                <div className='relative w-5 lg:w-6 h-5 lg:h-6 cursor-pointer'>
                    <ImageNext className='w-5 lg:w-6' onClick={() => { handlerOpenStoreModal({ type: 'APP_STORE' }) }}  src='/images/apple-black-logo.webp' alt='play-store-logo' width={24} height={24}/>
                </div>
                <div className='relative w-5 lg:w-6 h-5 lg:h-6 cursor-pointer'>
                    <ImageNext className='w-5 lg:w-6' onClick={() => { handlerOpenStoreModal({ type: 'PLAY_STORE' }) }} src='/images/play-store-logo.webp' alt='play-store-logo' width={24} height={24} />
                </div>
            </div>
        </div>
    )
}

export default AppBar