import { useState } from 'react';
import HighlighYellowSection from 'molecules/HighlighYellowSection'
import ModalSubmitQuery from '../ModalSellOn/ModalSubmitQuery';


const YellowSectionDetails = () => {
    const [openSellModal, setOpenSellModal] = useState(false)
    
    const handlerSellModalOpen = () => {
        setOpenSellModal(true);      
      };

    const handlerModalClose = () => {
        setOpenSellModal(false);
      };

    return (
        <>
            <HighlighYellowSection
                className='bg-other-yellow-400 py-2 sm:py-3'
                title='Are you a manufacturer and want to sell your medicines PAN India? Sell on NirogStreet'
                onBtnText='Click Here'
                onSellClick={handlerSellModalOpen}
            />

            <ModalSubmitQuery heading='Submit your query' isModalOpen={openSellModal} setOpenSellModal={setOpenSellModal} handlerModalClose={handlerModalClose} requestType={2}/>           
        </>
    )
}

export default YellowSectionDetails