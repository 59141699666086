import React from 'react'
import Text from 'atoms/Text'
import Image from "next/legacy/image"
import IconRightArrow from 'atoms/SvgIcons/IconRightArrow.svg'
import BodyForModal from 'molecules/Modals/BodyForModal'
import Modal from 'atoms/Modal'
import Button from 'atoms/Button'

const ThankYouSellCard = ({ className = '', heading, isModalOpen, handlerThanksModalClose }) => {
    return (
        <Modal
            type="withoutCloseIcon2"
            className={`${className} relative p-0 max-w-[440px] min-h-auto`}
            isModalOpen={isModalOpen}
            isShowCloseBtn={true}
            handlerModalClose={handlerThanksModalClose}
            dataAutomation='sell-on'
        >
            <BodyForModal type="bodyType1" className="px-4 pt-10 pb-10 md:pt-10 md:pb-20 relative overflow-y-auto flex-grow object-contain items-center justify-center">
                <div className='flex justify-center w-full mb-0'>
                    <div className="image-container relative w-full h-full max-w-20 max-h-20">
                        <Image src='/images/greenLike.svg' alt='' layout="fill" className={'image'} />
                    </div>
                </div>

                <div className="w-full flex justify-center md:justify-start flex-col items-center md:items-start mx-auto md:max-w-[590px] p-4 pt-0 text-gray-200">
                    {heading && <Text className='text-gray-900 font-bold font-Montserrat text-1.5xl text-center w-full'>{heading}</Text>}
                    <Text type='subtitle' className='text-gray-600 md:text-base font-Open-Sans font-semibold mt-3 md:mt-5 text-base md:max-w-[360px] text-center mx-auto'>Thank you for submitting your inquiry. We will contact you at the earliest.</Text>
                </div>
                <div className='flex justify-center mt-1 md:mt-3'>
                    <Button onClick={()=> handlerThanksModalClose()} variant='Link' href={'/'} dataAutomation='thankyou-request-appointment' className='text-primary1-500 hover:underline font-semibold text-sm mx-auto inline-block'>
                        <span className='whitespace-nowrap'>Back to home</span> <IconRightArrow className='inline-block fill-none ml-1.5' size={18} />
                    </Button>
                </div>
            </BodyForModal>
        </Modal>
    )
}

export default ThankYouSellCard