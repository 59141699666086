import React, { useState, useEffect } from 'react'
import Button from 'atoms/Button'
import Modal from 'atoms/Modal'
import BodyForModal from 'molecules/Modals/BodyForModal'
import Paragraph from 'atoms/Paragraph'
import OTPInput from 'atoms/OTPInput'
import Heading from 'atoms/Heading'
import Alert from 'molecules/Alert'

const ModalOnSellOTP = ({ className, mobileNo, isModalOpen, handlerOtpModalClose, alertMsg, setAlertMsg, handlerThanksModalOpen, handleResendOtp, otpCallback = () => { } }) => {
    const [otpValue, setOtpValue] = useState(false);
    const [isDisableProceedBtn, setIsDisableProceedBtn] = useState(true);

    const hidePhoneNumber = (mobile) => {
        if (mobile) return mobile.replace(mobile.substring(0, 6), 'XXXXXX');
    }

    useEffect(() => {
        if (otpValue.length == 4) {
            otpCallback(otpValue)
        }
    }, [otpValue])

    useEffect(() => {
        if (otpValue.length === 4) {
            setIsDisableProceedBtn(false)
        } else {
            setIsDisableProceedBtn(true)
        }
    }, [otpValue.length])

    return (
        <Modal
            type="withoutCloseIcon2"
            className={`${className} relative p-0 max-w-[440px] min-h-auto`}
            isModalOpen={isModalOpen}
            isShowCloseBtn={true}
            handlerModalClose={handlerOtpModalClose}
            dataAutomation='sell-on'
        >
            {alertMsg?.message && <Alert type={alertMsg.type} effect='slideDown' resetAlertCallback={() => setAlertMsg(null)}>{alertMsg.message}</Alert>}
            <Heading type="h3" className='text-center pt-12'>Verification</Heading>
            <BodyForModal className="pt-4 pb-6 px-5 sm:px-8 relative overflow-y-auto flex-grow object-contain items-center justify-center">
                <Paragraph className="mb-8 lg:mb-10 text-center max-w-[300px] mx-auto w-full">
                    We have sent a 4- digit OTP via SMS and WhatsApp on {mobileNo && "+91 " + hidePhoneNumber(mobileNo)}
                </Paragraph>
                <OTPInput
                    className="flex justify-center"
                    length={4}
                    separator={<span>-</span>}
                    onChange={(otp) => { setOtpValue(otp) }}
                    otpValue={otpValue}
                />
                <div className={`flex justify-between mt-5`}>
                    <Button variant="Link" size="medium" fontSize='overline' onClick={() => { setOtpValue(''); handleResendOtp(); }} dataAutomation='resend-otp'>Re-send OTP</Button>
                </div>

                <div className='flex justify-center mt-8 sm:mb-8'>
                    <Button
                        className="w-200px"
                        size="medium"
                        variant="Primary"
                        onClick={() => { handlerThanksModalOpen(otpValue); setOtpValue(''); }}
                        disabled={isDisableProceedBtn}
                        dataAutomation='otp-proceed'
                    >
                        Proceed
                    </Button>
                </div>
            </BodyForModal>
        </Modal>

    )
}

export default ModalOnSellOTP